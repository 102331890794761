import React, { FC } from 'react';
import styled from 'styled-components';
import { ImSpinner3 } from 'react-icons/im';
import tw from 'twin.macro';

interface PropsType {
  size?: number;
}

const Spin: FC<PropsType> = props => {
  const { size = 30 } = props;

  return (
    <Wrapper>
      <Container size={size}>
        <ImSpinner3 className="icon-spin" size={size} />
      </Container>
    </Wrapper>
  );
};

export default Spin;

const Wrapper = styled.div`
  ${tw`absolute left-0 top-0 right-0 bottom-0 z-50`}
`;

const Container = styled.div<{ size: number }>`
  ${tw`absolute`}
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  left: calc(50% - ${({ size }) => size * 0.5}px);
  top: calc(50% - ${({ size }) => size * 0.5}px);
`;
