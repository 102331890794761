import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GoRepo } from 'react-icons/go';
import tw from 'twin.macro';

import { IPostTemplateContext } from 'src/interfaces/template';
import { formatDate } from 'src/utils/format';
import { UTTERANCES_REPO } from 'src/constant';
import { getTagSlug } from 'src/libs/common';
import Tag from 'src/components/molecules/tag/Tag';
import PageHeader from 'src/components/organisms/common/PageHeader';
import Utterances from 'src/components/organisms/comment/Utterances';

import RecentlyPostList from './RecentlyPostList';

interface PropsType {
  post: IPostTemplateContext;
}

const Post: FC<PropsType> = ({ post }) => {
  return (
    <>
      <PageContent className="content">
        <PageHeader title={post.title}>
          <InfoBlock>
            <Date title={formatDate(post.date, 'PPpp')}>{formatDate(post.date)}</Date>
            {post.series && (
              <SeriesLink to={post.series.slug}>
                <SeriesContent>
                  <GoRepo size={25} /> <span>{post.series.name}</span>
                </SeriesContent>
              </SeriesLink>
            )}
          </InfoBlock>
        </PageHeader>
        <Content dangerouslySetInnerHTML={{ __html: post.html }} />
      </PageContent>
      <TagBlock>
        {post.tags.map(tag => (
          <Tag key={tag} slug={getTagSlug(tag)} name={tag} />
        ))}
      </TagBlock>
      {post.comments && <Utterances repo={UTTERANCES_REPO} />}
      <RecentlyPostList posts={post.recently} />
    </>
  );
};

export default Post;

const PageContent = styled.div``;
const InfoBlock = styled.div``;

const Date = styled.span`
  ${tw`text-base text-gray-700 dark:text-gray-300`}
`;

const SeriesLink = styled(Link)`
  ${tw`float-right hover:text-primary`}
`;

const SeriesContent = styled.div`
  ${tw`flex items-center gap-1 text-lg`}
`;

const Content = styled.div`
  ${tw`my-6`}

  p {
    img {
      ${tw`block mx-auto dark:bg-gray-100`}
    }
  }

  a {
    ${tw`text-primary`}
  }
`;

const TagBlock = styled.div`
  ${tw`mb-8`}
`;
