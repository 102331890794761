import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import tw from 'twin.macro';

import { IPost } from 'src/interfaces/template';
import { formatDate } from 'src/utils/format';
import List from 'src/components/organisms/common/List';

interface PropsType {
  posts: IPost[];
}

const RecentlyPostList: FC<PropsType> = ({ posts }) => {
  if (!Array.isArray(posts)) {
    posts = [];
  }

  if (posts.length === 0) {
    return <></>;
  }

  return (
    <RecentlyPostContainer>
      <BlockTitle>
        <span>Recently posts</span>
      </BlockTitle>
      <List
        dataSource={posts.map(post => {
          return {
            ...post,
            key: post.slug,
          };
        })}
        renderItem={(item: IPost) => (
          <Block>
            <Link to={item.slug}>
              <Meta>
                <Title>{item.title}</Title>
                <Description>
                  <span title={formatDate(item.date, 'PPpp')}>{formatDate(item.date)}</span>
                </Description>
              </Meta>
              <Content>{item.excerpt}</Content>
            </Link>
          </Block>
        )}
      />
    </RecentlyPostContainer>
  );
};

export default RecentlyPostList;

const RecentlyPostContainer = styled.div`
  ${tw`mt-12 p-4 border-t border-gray-300 dark:border-gray-700 border-dotted`}
`;

const Block = styled.div`
  ${tw`mb-8`}
`;

const BlockTitle = styled.div`
  ${tw`text-2xl font-bold tracking-wider mb-3`}
`;

const Meta = styled.div``;

const Title = styled.div`
  ${tw`text-lg tracking-wider font-bold mb-2`}
`;

const Description = styled.div`
  ${tw`text-sm text-gray-600 dark:text-gray-400`}
`;

const Content = styled.p`
  ${tw`text-base my-1`}
`;
