import React, { FC } from 'react';

import SEO from 'src/components/organisms/common/SEO';
import Post from 'src/components/organisms/post/Post';
import { IPostTemplateContext } from 'src/interfaces/template';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {
  post: IPostTemplateContext;
}

const PostTemplate: FC<PropsType> = ({ post }) => {
  return (
    <Layout>
      <SEO title={post.title} />
      <Post post={post} />
    </Layout>
  );
};

export default PostTemplate;
