import React, { FC, createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { useLayoutState } from 'src/contexts/layout/LayoutStateContext';
import Spin from 'src/components/molecules/common/Spin';

interface PropsType {
  repo: string;
}

const Utterances: FC<PropsType> = ({ repo }) => {
  const containerRef = createRef<HTMLDivElement>();
  const { theme } = useLayoutState();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const utterances = document.createElement('script');
    const attributes = {
      repo,
      'issue-term': 'pathname',
      src: 'https://utteranc.es/client.js',
      label: 'comment',
      theme: theme === 'dark' ? 'github-dark' : 'github-light',
      crossOrigin: 'anonymous',
      async: 'true',
    };

    Object.entries(attributes).forEach(([key, value]) => {
      utterances.setAttribute(key, value);
    });

    utterances.addEventListener('load', () => {
      setLoading(false);
    });

    while (containerRef.current.firstChild) {
      containerRef.current.removeChild(containerRef.current.lastChild);
    }
    containerRef.current.appendChild(utterances);
  }, [repo, theme]);

  return (
    <Wrapper>
      <div ref={containerRef} />
      {loading && (
        <Loading>
          <Spin />
        </Loading>
      )}
    </Wrapper>
  );
};

export default Utterances;

const Wrapper = styled.div`
  ${tw`w-full relative`}
  min-height: 269px;
`;

const Loading = styled.div`
  ${tw`w-full h-full bg-gray-300 dark:bg-gray-700`}
`;
