import React, { FC, memo } from 'react';

import { IPostTemplateContext, ITemplateProps } from 'src/interfaces/template';
import PostTemplate from 'src/components/templates/post/PostTemplate';

interface PropsType extends ITemplateProps<IPostTemplateContext> {}

const PostPageTemplate: FC<PropsType> = memo(({ pageContext }) => {
  return <PostTemplate post={pageContext} />;
});

export default PostPageTemplate;
